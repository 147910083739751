* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/* .container{
    display: flex;
    

} */

.scrollbar::-webkit-scrollbar {
  display: none;
}

.logo {
  margin-bottom: 30px;
}

.main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  background-color: white;
  width: 80%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  transition: all 0.3s;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-loggedin-user {
  width: 244px;
  height: 100px;
  margin: auto auto 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  place-self: center;
}

.sidebar-loggedin-logo {
  background: #255cff;
  color: #ffffff;
  width: 46.2px;
  height: 46.2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.sidebar-loggedin-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar-loggedin-name {
  color: #000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}
.sidebar-loggedin-role {
  color: #737373;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.bars {
  background: #255cff;
  color: white;
  width: 30px;
  height: 25px;
  margin-left: 20px;
  margin-top: 20px;
  padding-top: 3px;
  padding-bottom: 2px;
  font-weight: 900;
  font-size: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: none;
}

.link {
  display: flex;
  color: #737373;
  padding: 10px 15px;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
  transition: all 0.5s;
}

/* .link:hover{
    width: 88.3%;
    height: 30px;
    background: rgba(255, 84, 61, 0.17);
    color: #FF543D;
    border-right: 5px solid #FF543D ;

} */

.active {
  background: #255cff;
  min-width: 33%;
  color: #fff;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 50px;
}

.sidebar-toggle {
  width: 35%;
  height: 41px;
  left: 20px;
  bottom: 100px;
  display: flex;
  column-gap: 20px;
  position: absolute;
  background: #e7e7e7;
  border-radius: 30px;
  cursor: pointer;
}

.lightMode-bg {
  position: relative;
  width: 44px;
  height: 31px;
  left: 10px;
  top: 5px;
  text-align: center;
  color: #737373;
  background: #ffffff;
  border-radius: 22.6829px;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #255cff !important;
  bottom: -2px !important;
}
