.disable-container {
  padding: 50px 0 47px 39px;
  display: flex;
  flex-direction: row;
}

.disable-select-container {
  margin-left: 70px;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.disable-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

.disable-select p {
  color: #000000;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.disable-select-cont {
  width: 65px;
  height: 31px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable-select-button {
  background-color: #f4f1ec;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
}
.disable-select-button::before {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 5px;
  transition: 0.2s;
}

.disable-select input:checked + .disable-select-button {
  background-color: #255cff;
}
.disable-select input:checked + .disable-select-button::before {
  transform: translateX(36px);
}

.disable-select input {
  display: none;
}

input[type="radio"] {
  width: 19px;
  height: 19px;

  cursor: pointer;
}
input[type="radio"]:checked {
  background: #ff543d;
}

.rate-container {
  padding: 37px 0 16px 39px;
}

.rate-container select {
  background: #fafafa;
  color: #000000;
  width: 100px;
  height: 33px;
  padding-left: 5px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  border: 0.7px solid #e2e2e2;
  border-radius: 3.3px;
  outline: none;
}

.settings-btn-cont {
  width: 120px;
  height: 36.5px;
  margin: 57px 86px 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}
.settings-btn {
  background: #255cff;
  color: #ffffff;
  width: 72px;
  height: 36.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.settings-btn:disabled {
  background: #9f9f9f;
}

.settings-btn:active {
  opacity: 0.8;
}
