.user-header-title-div {
  display: flex;
  column-gap: 39px;
  align-items: center;
}

.user-header-title-text {
  color: #000;
  font-size: 40px;
  font-family: "Open-Sans";
  font-weight: 700;
}

.user-header-title-super {
  color: #e490f6;
  width: 127px;
  height: 37px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e490f6;
  border-radius: 43px;
  box-sizing: border-box;
}

.user-table-header {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0 5px;
}

.user-table-header-div {
  width: 100%;
  display: flex;
  padding-right: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.user-table-header-text {
  color: #000;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

.user-table-header-button {
  background: #255cff;
  color: #ffffff;
  width: 117px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.new-user-form {
  background: #fff;
  width: 87%;
  min-height: 800px;
  padding: 56px 70px;
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  border-radius: 20px;
}
.new-user-form-input-cont {
  width: 100%;
  margin-bottom: 39px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 10px;
  border-color: #d9d9d9;
}

.new-user-form-label {
  color: #000000;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.new-user-form-input {
  background: #eef0f4;
  color: #959595;
  width: 100%;
  height: 55px;
  padding: 0px 10px;
  display: inline-block;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}

.new-user-form-input:focus {
  border-color: #255cff;
}

.new-user-form-input::placeholder {
  color: #959595;
  font-family: "Open-Sans";
  font-size: 18px;
  font-weight: 500;
}

.new-user-form-button-footer {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-user-form-text {
  color: #737373;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.new-user-form-button-cont {
  width: 210px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.new-user-form-button {
  width: 94px;
  height: 36px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 7.7px;
  cursor: pointer;
}
