.login-container {
  padding: 23px 20px;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow-y: hidden;
}

.login-form-bg {
  /* position: relative; */
  /* width: 60%; */
  min-height: 100vh;
  padding: 0 35px 45px 0px;
  background: #ffffff;
  flex: 1;
}

.login-img-bg {
  /* position: absolute;
  height: 100vh;
  */
  width: 58%;
  flex: 1;
  background: white;
}

.breezelearnLogo {
  width: 100%;
  margin: 6px 0 30px 50px;
}

.login-form-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  padding-top: 64px;
}

.login-form-sub-header {
  color: #737373;
  max-width: 395px;
  margin-top: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 30px;
  margin: 0 auto;
}

.form-group {
  margin-left: 12%;
  margin-top: 77px;
  margin-right: 4rem;
}

label {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.login-input {
  background: inherit;
  color: #737373;
  width: 100%;
  height: 60px;
  margin-top: 11px;
  padding-left: 10px;
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  outline-style: none;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1.5px solid #bfbfbf;
}

.login-input:focus {
  border: 1.5px solid #255cff;
}

.username-input,
.password-input {
  margin-bottom: 37px;
  position: relative;
}

.login-input::placeholder {
  color: #255cff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3857px;
  line-height: 28px;
}
.authentication-form-group {
  width: 100%;
  max-width: 700px;
  margin: 77px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.authentication-form-sub-header {
  color: #737373;
  max-width: 460px;
  padding-top: 21px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 30px;
  margin: 0 auto;
}

.authentication-input-cont {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  margin-bottom: 37px;
  display: flex;
  column-gap: 10px;
}

.authentication-input {
  background: inherit;
  color: #737373;
  width: 100%;
  height: 63px;
  margin-top: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  box-sizing: border-box;
}

.authentication-input:focus {
  border: 3px solid #255cff;
}

.authentication-input::placeholder {
  color: #d4d4d4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3857px;
  line-height: 28px;
}

.login-btn {
  background: #255cff;
  color: #ffffff;
  width: 100%;
  height: 64px;
  margin-top: 95px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  outline-style: none;
  cursor: pointer;
  border-radius: 15px;
  border: none;
}

.login-btn:disabled {
  background: #b3b3b3;
}

.eye-icon {
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  cursor: pointer;
}

.eye-icon:focus {
  color: red;
}
