.text-encrypt-input-container {
  width: 100%;
  height: 70%;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.text-encrypt-input {
  color: #000000;
  width: 300px;
  height: 110px;
  padding: 6px;
  outline: none;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  resize: none;
  border: 1px solid #9f9f9f;
  border-radius: 4px;
}

.text-encrypt-btn {
  background-color: #255cff;
  color: #ffffff;
  width: 130px;
  height: 44px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
