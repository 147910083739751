.ruleConfig-header {
  width: 85%;
  color: #737373;
  margin: 21px 0px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.ruleConfig-tabs {
  width: 98%;
  height: 74px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #255cff0f;
  border-radius: 20px;
}
.ruleConfig-tabs-box {
  width: 84%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.css-heg063-MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between !important;
  margin-top: 10px;
  margin-bottom: 5px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 36px !important;
}

.ruleSec-btn-container {
  display: flex;
  column-gap: 25px;
}

.ruleSec-btn {
  width: 104px;
  cursor: pointer;
  height: 48px;
  background: #bbbbbb;
  border: #bbbbbb;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 15px;
}

.ruleSec-reset-btn {
  background: #255cff;
  color: #ffffff;
  width: 80px;
  height: 34px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  outline-style: none;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.ruleSec-reset-btn[disabled] {
  background: #aeaeae !important;
}

.ruleName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #000000;
}

.ruleName-input {
  width: 224px;
  height: 48px;
  left: 0px;
  top: 42px;
  padding-left: 20px;
  outline-style: none;
  background: #ffffff;
  border: 1px solid #1cc218;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #737373;
  margin-top: 20px;
}

.ruleType-input {
  width: 224px;
  height: 48px;
  left: 0px;
  top: 42px;
  padding-left: 20px;
  outline-style: none;
  background: #ffffff;
  border: 1px solid #ff543d;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #737373;
  margin-top: 20px;
  cursor: pointer;
}

.ruleSec-card-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-top: 83px;
}

.ruleSec-formControl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.rule-edit-btn {
  height: 48px;
  background: #ff543d;
  border: #ff543d;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.rule-config-checkbox-cont {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rule-config-checkbox-name-cont {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  column-gap: 0px;
}

.rule-config-checkbox-name-label {
  color: #000;
  height: 24px;
  margin-left: -20px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-family: "Open-Sans";
  font-size: 16px;
  border-radius: 30px;
}

.rule-approval-table-row {
  width: 100%;
  height: 50px;
}

.rule-approval-table-header-data {
  text-align: center;
  width: 50%;
  border: 1px solid #e8e8e8;
}

.rule-approval-table-header-data p {
  background: #f3f3f3;
  height: 25px;
  padding: 0px 12px;
  display: inline-block;
  border-radius: 10px;
  font-family: "Open-Sans";
}

.rule-approval-table-data {
  width: 50%;
  border: 1px solid #e8e8e8;
}
.rule-approval-table-data-cont {
  width: 94%;
  padding: 0px 20px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rule-approval-table-data-cont h5 {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}
.rule-approval-table-data-cont p,
.rule-approval-table-data-d-cont p {
  color: #000000;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
}
.rule-approval-table-data-d-cont {
  width: 90%;
  padding: 0px 20px 0px 10px;
  display: flex;
  justify-content: flex-end;
}

.rule-approval-mtn {
  background: rgba(255, 232, 21, 0.1);
  height: 20px;
  padding: 0px 6px;
  border: 1.5px solid #ffe815;
  border-radius: 10px;
}

.rule-approval-airtel {
  background: rgba(255, 84, 61, 0.15);
  height: 20px;
  padding: 0px 6px;
  border: 1.5px solid #ff543d;
  border-radius: 10px;
}

.rule-approval-glo {
  background: rgba(28, 194, 24, 0.15);
  height: 20px;
  padding: 0px 6px;
  border: 1.5px solid #1cc218;
  border-radius: 10px;
}

.rule-approval-9mobile {
  background: rgba(2, 87, 0, 0.15);
  height: 20px;
  padding: 0px 6px;
  border: 1.5px solid #025700;
  border-radius: 10px;
}

.rule-approval-date {
  background: #f3f3f3;
  padding: 1px 10px;
  border-radius: 10px;
}

.rule-config-history {
  background: #255cff;
  color: #fff;
  width: 234px;
  height: 38px;
  margin: 0 auto;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}
