/* .forms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 22px;
} */
.vendor-list-container {
  background: #fff;
  max-width: 1050px;
  max-height: 800px;
  overflow-y: scroll;
  border-radius: 20px;
}

.vendor-list-container::-webkit-scrollbar {
  width: 6px;
}

.vendor-list-container::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;

  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888;
}

.add-vendor-forms input {
  color: #000000;
  width: 497px;
  height: 51px;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #737373;
  border-radius: 15px;
  outline-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.add-vendor-forms input::placeholder {
  color: #989898;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.add-vendor-forms input:focus {
  border: 1px solid #255cff;
}

.add-vendor-forms select {
  background: #ffffff;
  color: #000000;
  width: 520px;
  height: 45px;
  padding: 0px 10px;
  border: 1px solid #737373;
  border-radius: 15px;
  outline-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.add-vendor-forms select:focus {
  border: 1px solid #255cff;
}

.add-vendor-forms select::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.vendorSec-btn {
  width: 105px;
  cursor: pointer;
  height: 48px;
  background: #bbbbbb;
  border: #bbbbbb;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 15px;
}

.card1_bg {
  width: 269px;
  height: 170px;
  margin: 55px 0 0 0;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 20px;
  box-shadow: none;
}

.vendor-name {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  color: #000;
  text-align: center;
}

.vendor-name span {
  font-size: 18px;
}

.vendor-info {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 15px;
}

.vendor-info span {
  opacity: 0.6;
}

.card2_body {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.card2_body span {
  margin-left: 20px;
}

.card2_body img {
  width: 40px;
}

.card2_subtext {
  color: #000;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 17.7089px;
  line-height: 27px;
}

.card3_text {
  color: #000;
  margin-left: 25px;
  margin-top: 16px;
  margin-bottom: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.card3_subtext {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-left: 25px;
  color: #737373;
  width: 80%;
}
.btn-add-vendor {
  background: #255cff;
  color: #fff;
  width: 198px;
  height: 48px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn-add-vendor:hover {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 6px 6px -6px #777;
  width: 15%;
}
.vendor-action-pending-text {
  color: #ff9515;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

.vendor-action-inactive-text {
  color: #ff4444;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}
.vendor-action-btn {
  color: #ffffff;
  background: #255cff;
  width: 96px;
  height: 42px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
