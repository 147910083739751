.table-header {
  color: #000000;
  height: 60px;
  font-family: "Open-Sans";
  font-size: 14px;
  font-weight: 600;
}

.table-content {
  color: #737373;
  height: 55px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  box-sizing: border-box;
}
.table-details-btn {
  background: #255cff;
  color: #ffffff;
  width: 90px;
  height: 30px;
  margin: 0 auto;
  font-family: "Open-Sans";
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}
.table-pagination-cont {
  height: 35px;
  width: 100%;
  margin-top: 120px;
  margin-right: 30px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.table-pagination-footer-cont {
  height: 35px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.table-pagination-dropdown {
  color: #b5b5b5;
  width: 71px;
  height: 100%;
  border: 1px solid #255cff;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Open-Sans";
}
.table-pagination-size {
  color: #255cff;
  font-family: "Open-Sans";
  font-size: 17px;
  font-weight: 500;
}
.table-pagination-btn-cont {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.table-pagination-btn {
  background: inherit;
  color: #255cff;
  width: 85px;
  height: 100%;
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.table-pagination-btn-disabled {
  background: inherit;
  color: #b5b5b5;
  width: 85px;
  height: 100%;
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
