.page-access-header-title {
  color: #000;
  font-size: 40px;
  font-family: "Open-Sans";
  font-weight: 700;
}

.page-access-form-check-cont {
  margin-right: 30px;
  font-size: 18px;
  font-family: "Open-Sans";
  font-weight: 500;
  display: flex;
  align-items: center;
}
.page-access-form-check-label {
  color: #255cff;
  height: 35px;
  margin-left: -15px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  border-radius: 10px;
  background: #255cff26;
}

.page-access-btn-cont {
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  column-gap: 36px;
  place-self: center;
}

.page-access-btn-cont button {
  color: #fff;
  width: 100px;
  height: 45px;
  font-size: 18px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 7.748px;
  border: 0.775px solid #d9d9d9;
  cursor: pointer;
}
