.error-container {
  background: #fff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-header {
  color: #000;

  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
}

.error-sub-header {
  color: #000;
  max-width: 500px;
  margin-top: 20px;
  text-align: center;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 26px;
  line-height: 55px;
}
