.help-container {
  width: 93%;
  margin: 0px auto;
}

.help-header {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.help-form {
  width: 100%;
  margin-top: 37px;
}

.help-label {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3857px;
  line-height: 28px;
  color: #818181;
}

.help-input {
  color: #000;
  width: 93%;
  height: 50px;
  margin-top: 14px;
  margin-bottom: 14px;
  padding-left: 15px;
  padding-right: 20px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3857px;
  line-height: 28px;
  outline-style: none;
  border-radius: 5px;
  border: 1.5px solid rgba(115, 115, 115, 0.49);
}

.help-input:focus {
  border: 1px solid #255cff;
}

.help-input-textarea:focus {
  border: 1px solid #255cff;
}
/* .help-input::after{
    border: 2px solid green;
} */

.help-input::placeholder,
.help-input-textarea::placeholder {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3857px;
  line-height: 28px;
  color: #bbbbbb;
}

.help-input-textarea {
  color: #000;
  width: 93%;
  height: 246px;
  margin-bottom: 14px;
  margin-top: 14px;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 20px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3857px;
  line-height: 28px;
  outline-style: none;
  resize: none;
  border-radius: 5px;
  border: 1.5px solid rgba(115, 115, 115, 0.49);
}

.help-btn-container {
  margin-top: 20px;
}

.help-btn-text {
  width: 20%;
  height: 48px;
  background: #255cff;
  border-radius: 5px;
  text-align: center;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: #ff543d;
  cursor: pointer;
}
