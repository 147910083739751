.history-table-row {
  width: 100%;
  height: 70px;
}

.history-table-data {
  width: 50%;
  border: 1px solid #e8e8e8;
}
.history-table-data-cont {
  width: 90%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.history-table-data-cont h5 {
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.history-table-data-cont p {
  color: #737373;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.rule-history-button {
  background-color: inherit;
  color: #ff543d;
  margin: 90px auto 0px auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.rule-history-button:disabled {
  color: #737373;
  opacity: 0.5;
}
